/* Application du style global */
.app {
  font-family: var(--font-text);
  color: var(--text-color);
  /* background-image: url('/path/to/album/cover.jpg'); */
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  padding: var(--space-4);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  /* overflow: hidden; */
}

/* Overlay pour améliorer la lisibilité */
.app:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7); /* Overlay noir semi-transparent */
  z-index: -1;
}

/* Style de la section principale */
.main-content {
  display: flex;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.7); /* Légère opacité */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  height: 100%;
  box-sizing: border-box;
}

/* Style du bloc de gauche */
.left-sidebar {
  flex: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  max-height: 100%; /* Hauteur maximale pour empêcher le débordement */
}

/* Style du bloc de droite */
.right-content {
  flex: 2;
}

/* Style de l'en-tête de l'album */
.album-header {
  text-align: center;
}

.album-cover {
  max-width: 100%;
  border-radius: 10px;
}

.album-title {
  font-size: var(--font-large);
  font-family: var(--font-title);
}

.current-track-title {
  font-size: var(--font-regular);
  color: var(--accent-color);
}

/* Style de la liste de pistes (déroulante) */
.tracklist-container {
  flex-grow: 1; /* Permet à la liste de pistes de prendre tout l'espace restant */
  overflow-y: auto; /* Active le défilement si le contenu dépasse */
  padding-right: var(
    --space-2
  ); /* Un peu d'espace à droite pour le défilement */
  margin-top: var(--space-2);
}

.tracklist {
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
}

:root {
  /* ... Autres couleurs */
  --color-intro: #4caf50; /* Vert */
  --color-outro: #ff5722; /* Orange */
  --color-interlude: #2196f3; /* Bleu */
  --color-remix: #9c27b0; /* Violet */
  --color-bonus: #ffc107; /* Jaune-Or */
  --color-default-track: #607d8b; /* Bleu-gris légèrement saturé */
}

.track {
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: rgba(
    33,
    33,
    33,
    0.8
  ); /* Ajustement pour mieux s'harmoniser */

  padding: 10px;
  border-radius: 5px;
  margin-bottom: 5px;
  border-left: 4px solid var(--color-default-track); /* Bordure discrète */
  /* transition: background-color 0.3s, box-shadow 0.3s; */
}

.track:hover {
  background-color: var(--neutral-color-8);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.track.selected {
  background-color: var(--primary-color-8);
  color: var(--text-color);
}

.track.intro {
  border-left: 4px solid var(--color-intro);
}

.track.outro {
  border-left: 4px solid var(--color-outro);
}

.track.interlude {
  border-left: 4px solid var(--color-interlude);
}

.track.remix {
  border-left: 4px solid var(--color-remix);
}

.track.bonus {
  border-left: 4px solid var(--color-bonus);
}

.track-cover {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.track-title {
  font-weight: bold;
  color: var(--text-color);
}
.welcome-modal {
  padding: var(--space-3);
}

.welcome-modal .modal-content {
  max-width: 600px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
}

.welcome-modal-content {
  text-align: center;
  height: 100%;
  overflow-y: auto;
}

.album-modal-text {
  padding: var(--space-3);
}

.album-modal-cover {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  margin-bottom: 20px;
}

.welcome-modal-content h2 {
  font-size: var(--font-xlarge);
  color: var(--primary-color);
  margin-bottom: 15px;
}

.welcome-modal-content p {
  font-size: var(--font-regular);
  color: var(--neutral-color);
  margin-bottom: 20px;
}

.welcome-modal-content button {
  background-color: var(--accent-color);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: var(--font-regular);
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.welcome-modal-content button:hover {
  /* background-color: darken(var(--accent-color), 10%); */
}

/* Ajout de Media Queries dans index.css */
@media only screen and (max-width: 958px) {
  :root {
    /* Ajustement des tailles de police pour les mobiles */
    --font-large: 1rem;
    --font-xlarge: 1.2rem;
  }

  /* Autres variables mobile-specific si nécessaire */
}

/* Ajout de Media Queries dans App.css */
@media only screen and (max-width: 958px) {
  .app {
    /* height: auto; */
  }

  .main-content {
    display: flex;
    flex-direction: column; /* Important pour le mobile */
    max-width: 1200px;
    margin: auto;
    padding: var(--space-3);
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    height: 100%; /* Assurez-vous que cela occupe tout l'espace */
    box-sizing: border-box;
    overflow: hidden; /* Empêche le débordement externe */
  }

  .tab-content {
    flex-grow: 1; /* Prend tout l'espace restant */
    overflow-y: auto; /* Permet le défilement à l'intérieur */
    padding-bottom: var(--space-3); /* Ajustement pour le padding */
    width: 100%; /* Ajustement pour le padding */
  }

  .left-sidebar,
  .right-content {
    margin-right: 0;
    width: 100%;
  }

  .album-header {
    margin-bottom: var(--space-3);
  }

  .tracklist-container {
    padding-right: var(--space-1);
    /* remove desktop-specific styles */
  }

  /* Autres ajustements de style pour le mobile */
}

@media only screen and (max-width: 958px) {
  /* Styles pour mobile */

  .album-header {
    position: relative;
    text-align: center;
  }

  .album-cover {
    width: 100%;
    border-radius: 0; /* Modification pour mobile */
  }

  .player-overlay {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* Style supplémentaire pour l'overlay */
    background-color: rgba(0, 0, 0, 0.5);
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .tab-buttons {
    display: flex;
    justify-content: center;
    margin-top: var(--space-3);
  }

  .tab-buttons button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: var(--space-2);
    margin: 0 var(--space-1);
    border-radius: var(--border-radius);
    cursor: pointer;
  }

  .tab-buttons button:focus {
    outline: none;
  }

  .welcome-modal-content {
    display: flex;
    flex-direction: column;
  }

  .album-modal-text {
    padding-top: 0;
  }

  .album-modal-cover {
    margin-bottom: 0;
  }
}

/* hide the scroll bar style on both */
.album-modal-text::-webkit-scrollbar,
.welcome-modal-content::-webkit-scrollbar {
  display: none;
}

/* hide the scroll bar on the tab content */
.tab-content::-webkit-scrollbar {
  display: none;
}
